<script async setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { ref, Ref, computed, watch, nextTick, onMounted } from 'vue'
import { Notify } from 'quasar'
import { removeAccents } from '@/utils/stringUtils'
import { getNode } from '@/components/projects/execution/documents/DocumentsCommon'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'


const userApi: UserApi = useApi()
const authenticatedUser = useAuthenticatedUser()

console.log('authenticatedUser', authenticatedUser)

const emit = defineEmits(['createProjectWithSubprojects', 'addSubProjects', 'createBasicDocuments'])

const documentTree = await userApi.getUserProjectDocumentTree("0ee32efc-4eb4-4ddc-8538-3da3adac5c61")
const environmentDocumentCatalogResponse = await userApi.getEnvironmentDocumentCatalog(authenticatedUser.environment.pkid)

const allCategories = [
  "ATMP",
  "Commercial",
  "Corporate",
  "Déontologie de l'avocat",
  "Procédure civile",
  "Procédure pénale",
  "Social",
  "Voies d'exécution"
]
const categories = environmentDocumentCatalogResponse.catalogJson

function parseDocumentTree(nodes) {
  const result = []

  nodes.forEach((child) => {
    let newNode
    // console.log("documentCatalog - parsing", child)
    if (child.type == 1) {
      if (allCategories.includes(child.name) && !categories.includes(child.name)) {
        console.log("documentCatalog - skipping", child)
        return
      }

      newNode = {
        id: child.id,
        label: child.name,
        type: 'category',
        children: [],
      }
    } else {
      newNode = {
        id: child.id,
        label: removeFileExtension(child.name),
        type: 'basicDocumentTreeModel',
        fileName: child.name,
        children: [],
      }
    }

    newNode.children = parseDocumentTree(child.children)

    result.push(newNode)
  })

  result.sort((a, b) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1
    } else if (a.label > b.label) {
      return 1
    }
    return 0
  })

  return result
}

const parsedDocumentTree = parseDocumentTree(documentTree.children)

const projectCatalog = parsedDocumentTree[0].children
console.log("documentCatalog", projectCatalog)


const ticked = ref([])
const expandedNodes = ref(['root'])
const searchText = ref('')
const treeFilter = ref('')
const treeRef = ref(null)
const projectsToCreate = ref(null)
const nodes: Ref<Array<any>> = ref([])
const tab = ref('generic')


function removeFileExtension(fileName) {
  const lastDotIndex = fileName.lastIndexOf('.')
  if (lastDotIndex === -1) {
    return fileName
  }
  return fileName.substring(0, lastDotIndex)
}

function parsePersonalModelsDirectory(documentTreeNode) {
  const result = []

  documentTreeNode.children.forEach((child) => {


    if (child.type === 1) {
      const personalModelNode = {
        id: child.id,
        label: child.name,
        type: 'subject',
        children: parsePersonalModelsDirectory(child)
      }

      result.push(personalModelNode)
    }

    if (child.type === 2) {
      const personalModelNode = {
        id: child.id,
        label: removeFileExtension(child.name),
        type: 'basicDocumentTreeModel',
        fileName: child.name,
      }

      result.push(personalModelNode)
    }

  })

  return result
}

function parsePersonalModels(personalModels) {
  const result = []

  personalModels.forEach((personalModel) => {
    const personalModelNode = {
      id: personalModel.id,
      label: personalModel.documentTreeNode.name,
      type: 'subject',
      children: parsePersonalModelsDirectory(personalModel.documentTreeNode),
      isPersonalModel: true,
      documentTreeNodeId: personalModel.documentTreeNodeId,
      url: personalModel.url,
    }

    result.push(personalModelNode)
  })

  return result
}

let personalModels
personalModels = await userApi.getDocumentTreePersonalModelsWithSubTrees(true)

const catalogNodes = [{ id: 'root', label: '', type: 'root', children: projectCatalog }]
// Put the guides in another tab
let socialNodes = catalogNodes[0].children.find(node => node.label == 'Social')
const guides = socialNodes.children.filter((node) => {
  return node.label == 'à venir...'
})
const hasGuides = ref(guides.length > 0)
socialNodes.children = socialNodes.children.filter((node) => {
  return !guides.includes(node)
})
parseNodes(catalogNodes, undefined)
nodes.value = catalogNodes

let personalNodes = parsePersonalModels(personalModels)
const hasPersonalModels = ref(personalNodes.length > 0)
const personalNodeNumber = ref(personalNodes.length)
const filteredOptions = computed(() => {
  const options = [
    { label: 'Bible des modèles unitaires', value: 'generic' },
  ];
  if (hasPersonalModels.value) {
    options.push({ label: `Mes modèles (${personalNodeNumber.value})`, value: 'personal' });
  }
  if (hasGuides.value) {
    options.push({ label: 'Guides pratiques', value: 'guide' });
  }
  return options;
});

watch(tab, () => {
  ticked.value = []
  resetSearch()
  if (tab.value == 'generic') {
    nodes.value = catalogNodes
  } else if (tab.value == 'guide') {
    nodes.value = [ { id: 'root', label: '', type: 'root', children: guides } ]
    parseNodes(nodes.value, undefined)
  } else {
    nodes.value = [ { id: 'root', label: '', type: 'root', children: personalNodes } ]
    parseNodes(nodes.value, undefined)
  }
})

function addSearchText(node) {
  let currentNode = node

  if (node.searchText == undefined) {
    node.searchText = ''
  }
  node.searchText = node.searchText + ' ' + node.label

  while (currentNode != undefined) {
    node.searchText = node.searchText + ' ' + currentNode.label
    currentNode = currentNode.parent
  }
}

function parseNodes(nodes, parent) {
  if (nodes == undefined) {
    return
  }
  nodes.forEach((node) => {
    node.parent = parent
    if (node.type === 'root') {
      node.tickable = false
      node.noTick = true
    } else if (node.type === 'subject') {
      node.tickable = false
      node.noTick = true
      node.textStyle = 'font-size: 16px; font-weight: 700;'
    } else if (node.type === 'category') {
      node.tickable = false
      node.noTick = true
      node.textStyle = 'font-size: 16px; font-weight: 600;'
    } else if (node.type === 'subCategory') {
      node.tickable = false
      node.noTick = true
      node.textStyle = 'font-size: 16px; font-weight: 500;' // font-style: italic;"
    } else if (node.type === 'project') {
      node.tickable = true
      node.noTick = false
      node.icon = 'o_source'
      node.iconColor = 'primary'
      node.iconStyle = 'margin-top: -4px; margin-right: 5px; color: #4068c8;'
      node.textStyle = 'color: #4068c8;'
    } else if (node.type === 'basicModels') {
      node.tickable = true
      node.noTick = true
      node.textStyle = 'font-size: 16px; font-weight: 500;'
    } else if ((node.type === 'basicModel') || (node.type === 'basicDocumentTreeModel')) {
      node.tickable = true
      node.noTick = false
      node.icon = 'o_description'
      node.iconStyle = 'margin-top: -4px; margin-right: 5px; color: #22633f;'
      node.textStyle = 'color: #22633f;'

      // console.log('node.fileName', node)
      const lowerCaseName = node.fileName.toLowerCase()

      if (lowerCaseName.endsWith('.eml')) {
        node.icon = 'o_email'
      } else if (lowerCaseName.endsWith('.msg')) {
        node.icon = 'o_email'
      } else if (lowerCaseName.endsWith('.docx')) {
        node.icon = 'img:/word_icon.svg'
      } else if (lowerCaseName.endsWith('.doc')) {
        node.icon = 'img:/word_icon.svg'
      } else if (lowerCaseName.endsWith('.xlsx')) {
        node.icon = 'img:/excel_icon.svg'
      } else if (lowerCaseName.endsWith('.xls')) {
        node.icon = 'img:/excel_icon.svg'
      } else if (lowerCaseName.endsWith('.pptx')) {
        node.icon = 'img:/powerpoint_icon.svg'
      } else if (lowerCaseName.endsWith('.ppt')) {
        node.icon = 'img:/powerpoint_icon.svg'
      } else if (lowerCaseName.endsWith('.pdf')) {
        node.icon = 'img:/pdf_icon.svg'
      } else if (
        lowerCaseName.endsWith('.jpg') ||
        lowerCaseName.endsWith('.jpeg') ||
        lowerCaseName.endsWith('.png') ||
        lowerCaseName.endsWith('.gif')
      ) {
        node.icon = 'o_image'
      }

    }
    addSearchText(node)
    parseNodes(node.children, node)
  })
}

function filterNodes(nodes, parent) {
  if (nodes == undefined) {
    return
  }

  const childrenToRemove = []

  nodes.forEach((node) => {
    if (node.type == 'project') {
      childrenToRemove.push(node)
    }

    filterNodes(node.children, node)
  })

  if (parent != undefined) {
    console.log('childrenToRemove', childrenToRemove)
    parent.children = nodes.filter((node) => {
      return !childrenToRemove.includes(node)
    })
  }
}

// filterNodes(nodes.value, undefined)

function removeEmptySections(nodes, parent) {
  if (nodes == undefined) {
    return
  }

  nodes.forEach((node) => {
    removeEmptySections(node.children, node)
  })

  console.log(parent, nodes.length)
  if (parent && parent.children.length == 0) {
    if (parent.parent) {
      parent.parent.children = parent.parent.children.filter((node) => {
        return node !== parent
      })
    }
  }
}

// removeEmptySections(nodes.value, undefined)

function searchProjects() {
  console.log('searchProjects', searchText.value)

  if (searchText.value === '') {
    // treeRef.value.collapseAll();
    expandedNodes.value = ['root']
    treeRef.value.expandAll()
  } else {
    treeRef.value.expandAll()
  }

  treeFilter.value = searchText.value
}

function resetSearch() {
  searchText.value = ''
  searchProjects()
}

function applyTreeFilter(node, filter) {
  // console.log("applyTreeFilter", node, filter);

  const nodeNameLowerCase = removeAccents(node.searchText.toLowerCase())
  const filterLowerCase = removeAccents(filter.toLowerCase())

  const filterWords = filterLowerCase.split(' ')
  const foundAllWords = filterWords.every((filterWord) => {
    return removeAccents(nodeNameLowerCase).includes(filterWord)
  })

  if (foundAllWords && filter !== '') {
    // expandToNode2(documentTreeNodes, node.id, expandedNodes);
  }

  return foundAllWords
}

function createBasicDocuments() {
  const basicModels = []

  ticked.value.forEach((basicModelId) => {
    const node = getNode(nodes.value[0].children, basicModelId)

    basicModels.push({
      id: basicModelId,
      type: node.type,
      fileName: node.fileName,
    })
  })

  emit('createBasicDocuments', basicModels)
}

watch(ticked, () => {
  nextTick(() => {
    setScrollAreaHeight()
  })
})

const scrollAreaOffset = ref(0)

function setScrollAreaHeight() {
  scrollAreaOffset.value = 0
  console.log(
    'projectsToCreate.value',
    projectsToCreate.value,
    projectsToCreate.value.$el,
    projectsToCreate.value.clientHeight,
  )
  if (projectsToCreate.value && projectsToCreate.value) {
    scrollAreaOffset.value = projectsToCreate.value.clientHeight + 30
  }
  // scrollAreaOffset.value += 1
  console.log(scrollAreaOffset.value)
}

const scrollAreaStyle = computed(() => {
  console.log('computed', scrollAreaOffset.value)
  let width = '100%'
  if (treeRef.value != null) {
    console.log('treeRef', treeRef.value.$el, treeRef.value.$el.clientWidth)
    width = treeRef.value.$el.clientWidth + 'px'
  }
  return {
    width: width,
    height: 'calc(100vh - 330px - ' + scrollAreaOffset.value + 'px)',
    paddingRight: '5px',
  }
})

const thumbStyle = {
  right: '1px',
  borderRadius: '5px',
  width: '5px',
  color: 'rgb(71, 71, 71)',
  opacity: '0.2',
  zIndex: 10,
  // backgroundColor: '#027be3',
}

function getCompleteProjectPath(nodeId) {
  const node = getNode(nodes.value[0].children, nodeId)
  let completePath = node.label
  let currentNode = node.parent
  while (currentNode.type != 'root') {
    completePath = currentNode.label + ' / ' + completePath
    currentNode = currentNode.parent
  }
  return completePath
}

function removeProject(project) {
  ticked.value = ticked.value.filter((tickedProject) => {
    return tickedProject !== project
  })
}


function isPersonalModel(element) {
  // console.log("personalModels", personalModels, element.id)
  // return personalModels.find((model) => model.documentTreeNodeId === element.id)
  return element.isPersonalModel
}

async function removeFromPersonalModels(node) {
  console.log("removing from personal models", node)
  await userApi.removeDocumentTreeNodeFromPersonalModels(node.documentTreeNodeId)
  personalModels = await userApi.getDocumentTreePersonalModelsWithSubTrees(true)
  nodes.value[0].children[0].children = parsePersonalModels(personalModels)
  Notify.create({
    message: `Dossier supprimé des modèles personnels`,
    type: 'primary',
  })
}

function onMenuClick(event) {
  console.log('on menu click', event)
  event.preventDefault()
}

function goToPersonalModelProject(node) {
  console.log("goToPersonalModelProject", node)
  window.open(node.url, '_blank')
}
</script>

<template>
  <div style="width: 100%">
    <div class="row no-wrap" style="width: 90%">
      <div class="col" style="background-color: white; min-width: 5%"></div>
      <div class="col-auto" style="background-color: white">
          <div class="row">
            <div class="page-content-header">
              Sélectionnez les modèles de documents à ajouter :
            </div>
          </div>

          <div class="row">
            <q-input dense outlined v-model="searchText" @keydown.enter.prevent="searchProjects" class="search-input"
              @update:model-value="searchProjects">
              <template v-slot:append>
                <q-icon v-if="searchText === ''" name="search" size="" />
                <q-icon v-else name="clear" class="cursor-pointer" @click="resetSearch" />
              </template>
            </q-input>
          </div>

          <div v-if="hasPersonalModels || hasGuides" class="row no-wrap q-mb-md">
            <q-btn-toggle v-model="tab" spread class="full-width" no-caps dense toggle-color="primary" :options=filteredOptions />
          </div>

          <div class="row">
            <q-scroll-area :style="scrollAreaStyle" :thumb-style="thumbStyle">
              <q-tree dense :nodes="nodes" node-key="id" v-model:ticked="ticked" v-model:expanded="expandedNodes"
                tick-strategy="strict" :filter="treeFilter" :filter-method="applyTreeFilter"
                ref="treeRef" no-results-label="Aucun modèle trouvé">
                <template v-slot:default-header="prop">
                  <div style="padding: 0px; overflow: hidden"
                    class="row no-wrap justify-start items-start content-start full-width items-center">
                    <div v-if="prop.node.type === 'root'">&nbsp;</div>
                    <div v-if="prop.node.type !== 'root'"
                      class="row  no-wrap justify-start items-start content-start full-width items-center">
                      <div class="col-auto">
                        <q-icon v-if="prop.node.icon" :name="prop.node.icon" :style="prop.node.iconStyle"></q-icon>
                        <span :style="prop.node.textStyle">{{ prop.node.label }}</span>
                      </div>

                      <!-- space filler -->
                      <div class="col" style="overflow: hidden"></div>

                      <div class="col-auto" style="overflow: hidden">
                        <span class="row no-wrap items-center" style="text-align: center; line-height: 36px">
                          <q-icon v-if="isPersonalModel(prop.node)" name="o_snippet_folder" size="18px" class="q-ml-sm" />
                          <q-btn-dropdown v-if="isPersonalModel(prop.node)" flat rounded dropdown-icon="more_vert"
                            style="padding-left: 8px; padding-right: 8px" clickable ref="menuRef"
                            @click.stop="onMenuClick">
                            <q-list dense>
                              <q-item clickable v-close-popup @click="goToPersonalModelProject(prop.node)">
                                <q-item-section>
                                  <q-item-label>Aller au dossier</q-item-label>
                                </q-item-section>
                              </q-item>
                              <q-item clickable v-close-popup @click="removeFromPersonalModels(prop.node)">
                                <q-item-section>
                                  <q-item-label>Supprimer des modèles personnels</q-item-label>
                                </q-item-section>
                              </q-item>

                            </q-list>
                          </q-btn-dropdown>
                        </span>
                      </div>

                    </div>
                  </div>
                </template>
              </q-tree>
            </q-scroll-area>
        </div>
      </div>
      <div class="col" style="background-color: white; min-width: 5%"></div>
    </div>
    <!-- search -->

    <q-separator v-if="ticked.length > 0" style="margin-top: 8px" />

    <div class="" ref="projectsToCreate" id="projectsToCreate">
      <div class="col-auto" style="margin-left: 16px; margin-right: 16px" v-if="ticked.length > 0">
        <div class="page-content-header">Documents à créer :</div>

        <!-- <ul class="ul">
              <li v-for="tickedProject in ticked">
                {{ getCompleteProjectPath(tickedProject) }}
              </li>
            </ul> -->

        <q-list dense bordered separator>
          <q-item v-for="tickedProject in ticked">
            <q-item-section>{{ getCompleteProjectPath(tickedProject) }}</q-item-section>
            <q-item-section side>
              <q-btn rounded dense flat icon="clear" @click="removeProject(tickedProject)"></q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>
    <!-- projects to create -->

    <div class="row" style="margin-top: 16px; margin-right: 16px">
      <div class="col"></div>
      <div class="col-auto">
        <q-btn label="Valider" :disable="ticked.length == 0" style="" color="primary" @click="createBasicDocuments" />
      </div>
    </div>
    <!-- row button -->
  </div>
  <!-- main div -->
</template>
<style lang="scss" scoped>
.ul {
  list-style-type: disc;
}

:deep(.q-checkbox__inner) {
  color: rgb(158, 158, 158);

  margin-right: 5px;
  font-size: 34px; // influe sur la taille de la checkbox
}

// Hide tree root node
:deep(.q-tree > .q-tree__node > .q-tree__node-header) {
  height: 0px;
  width: 0px;
  overflow: hidden;
}

// change tree checkbox style
:deep(.q-checkbox__bg) {
  border: 1.3px solid currentColor;
}

.search-input {
  width: 870px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.subtitle {
  font-size: 18px;
}

.category-header {
  background-color: #227a68;
  color: white;
  font-size: 18px;
  font-weight: 700;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  // cursor: pointer;
}

.category-subheader {
  background-color: #2a5d97;
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin-left: 30px;
  margin-top: 10px;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  // cursor: pointer;
}

.project-category {
  color: rgb(61, 61, 63);
  font-size: 16px;
  font-weight: 700;
  padding-left: 10px;
}

.available-project {
  cursor: pointer;
  font-weight: 500;
}

.page-content-header {
  // margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
}

::-webkit-scrollbar {
  padding-left: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
