<script lang="ts" async setup>
import { ref, Ref, watch, onMounted, nextTick } from 'vue'
import { useDialogPluginComponent, Notify, Loading } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { Project } from '@/models/projects/Project'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import MatterNode from '@/components/common/permissions/deep_rights/MatterNode.vue'
import DocumentsNode from '@/components/common/permissions/deep_rights/DocumentsNode.vue'
import ProjectsNode from '@/components/common/permissions/deep_rights/ProjectsNode.vue'
import DirectoryNode from '@/components/common/permissions/deep_rights/DirectoryNode.vue'
import FileNode from '@/components/common/permissions/deep_rights/FileNode.vue'
import ProjectNode from '@/components/common/permissions/deep_rights/ProjectNode.vue'
import SectionNode from '@/components/common/permissions/deep_rights/SectionNode.vue'
import TaskNode from '@/components/common/permissions/deep_rights/TaskNode.vue'
import UsersNode from '@/components/common/permissions/deep_rights/UsersNode.vue'
import GroupsNode from '@/components/common/permissions/deep_rights/GroupsNode.vue'
import GroupNode from '@/components/common/permissions/deep_rights/GroupNode.vue'
import UserNode from '@/components/common/permissions/deep_rights/UserNode.vue'
import NotificationsNode from '@/components/common/permissions/deep_rights/NotificationsNode.vue'
import MessagesNode from '@/components/common/permissions/deep_rights/MessagesNode.vue'
import UsersAndGroupsNode from '@/components/common/permissions/deep_rights/UsersAndGroupsNode.vue'

interface Props {
  project: Project
}
const props = withDefaults(defineProps<Props>(), {
})

const userApi: UserApi = useApi()
const authenticatedUser = useAuthenticatedUser()
const isLoading: Ref<boolean> = ref(false)

const emit = defineEmits([...useDialogPluginComponent.emits])
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

let projectPermissions = null
let documentsPermissions = null

const splitterModel = ref(35)
const view = ref("1")
const view1PermissionsTreeRefMatter = ref()
const view1PermissionsTreeRefDocuments = ref()
const view1PermissionsTreeRefProjects = ref()
const view1PermissionsTreeRefNotifications = ref()
const treeRef2 = ref()
const view1UserOrGroupTreeRef = ref()
const treeRef4 = ref()
const expandedNodes = ref(["documents", "projets"])
const expandedNodes2 = ref(["documents", "projets"])
const expandedNodes3 = ref(["documents", "projets"])
const expandedNodes4 = ref(["documents", "projets"])
const selectedNode = ref([])
const selectedNode2 = ref([])
const view1UserOrGroupSelected = ref([])
const selectionIsGroup = ref(false)
const selectedNode4 = ref([])
const ticked = ref([])
const usersNodes = ref([])
const permissionsNodes = ref([])
const view1Tab = ref('matter')

let users = []
let groups = []
let documentTree = []
let documentTreeNodesIds = []

function userInGroup(user, groups) {
  let result = false
  console.log("userInGroup", user, groups)

  groups.clientGroups.forEach((group) => {
    if (group.users.find((groupUser) => {
      return groupUser.id === user.id
    })) {
      result = true
    }
  })

  groups.environmentGroups.forEach((group) => {
    if (group.users.find((groupUser) => {
      return groupUser.id === user.id
    })) {
      result = true
    }
  })

  return result
}

function filterUsersNotInGroups(users, groups) {
  let result = []

  users.forEach((user) => {
    if (!userInGroup(user, groups)) {
      result.push(user)
    }
  })

  return result
}

async function updateUsersAndGroups() {

  console.log("project", props.project.value)
  users = await userApi.getUsersWithProjectPermissions(props.project.value.id)
  console.log("users", users)

  groups = await userApi.getGroupsWithProjectPermissions(props.project.value.id)
  console.log("groups", groups)

  users = filterUsersNotInGroups(users, groups)



  documentTree = await userApi.getUserProjectDocumentTree(props.project.value.id)
  console.log("documentTree", documentTree)

  getDocumentTreeNodesIdsRecursive(documentTree.children)

  console.log("documentTreeNodesIds", documentTreeNodesIds)

  usersNodes.value = [
    {
      id: "users",
      type: "users",
      selectable: false,
      label: "Utilisateurs",
      children: [
      ],
    },
    {
      id: "groups",
      type: "groups",
      label: "Groupes",
      selectable: false,
      children: [
      ],
    }
  ]


  users.forEach((user) => {
    // if (!userInGroup(user, groups)) {
    usersNodes.value[0].children.push({
      id: user.id,
      type: 'user',
      label: user.fullName,
      permissions: [],
    })
    // }
  })

  view1UserOrGroupSelected.value = usersNodes.value[0]?.children[0]?.id

  groups.environmentGroups.forEach((group) => {
    const groupChildren = []
    group.users.forEach((user) => {
      groupChildren.push({
        id: user.id,
        type: 'user',
        label: user.fullName,
        permissions: [],
      })
    })

    usersNodes.value[1].children.push({
      id: group.id,
      type: 'group',
      label: group.name,
      permissions: [],
      children: groupChildren,
    })
  })

  groups.clientGroups.forEach((group) => {
    const groupChildren = []
    group.users.forEach((user) => {
      groupChildren.push({
        id: user.id,
        type: 'user',
        label: user.fullName,
        permissions: [],
      })
    })

    usersNodes.value[1].children.push({
      id: group.id,
      type: 'group',
      label: group.name,
      permissions: [],
      children: groupChildren,
    })
  })

  if (!view1UserOrGroupSelected.value) {
    view1UserOrGroupSelected.value = usersNodes.value[1]?.children[0]?.id
  }

  await updateUserOrGroupPermissions()

  updateDocumentsView(permissionsNodes.value[1].view)

}

function getDocumentTreeNodesIdsRecursive(nodes) {
  nodes.forEach((node) => {
    documentTreeNodesIds.push(node.id)
    if (node.children) {
      getDocumentTreeNodesIdsRecursive(node.children)
    }
  })
}

async function isFromGroup(nodeId, permissionName) {

  if (!view1UserOrGroupSelected.value) {
    return false
  }

  if (selectionIsGroup.value) {
    return false
  }

  const currentUser = users.find((user) => {
    return user.id === view1UserOrGroupSelected.value
  })
  const userGroup = currentUser.groups[0]

  if (!userGroup) {
    return false
  }

  console.log("isFromGroup", nodeId, permissionName, currentUser, userGroup)
  const result = await userApi.getObjectGroupPermissions(nodeId, "DocumentTreeNode", userGroup.id)
  console.log('isFromGroup result', result, result.permissions.includes(permissionName))
  return result.permissions.includes(permissionName)
}

async function parseDocumentTree(nodes) {
  const result = []

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    if (node.type === 1) {
      result.push({
        id: node.id,
        type: 'directory',
        label: node.name,
        icon: 'o_folder',
        permissions: [
          {
            name: 'view_documenttreenode',
            label: "Voir",
            value: getDocumentTreePermissions(node.id).includes("view_documenttreenode"),
            displayedValue: getDocumentTreePermissions(node.id).includes("view_documenttreenode"),
            disabled: !projectPermissions.permissions.includes("documents_edit_deep_rights"),
            fromGroup: await isFromGroup(node.id, "view_documenttreenode"),
          }, {
            name: 'documents_view_deep_rights',
            label: "Modifier",
            value: getDocumentTreePermissions(node.id).includes("change_documenttreenode"),
            displayedValue: getDocumentTreePermissions(node.id).includes("view_documenttreenode"),
            disabled: !projectPermissions.permissions.includes("documents_edit_deep_rights"),
            fromGroup: false,
          }
        ],
        children: [] // await parseDocumentTree(node.children)
      })
    }
    // else if (node.type === 2) {
    //   result.push({
    //     id: node.id,
    //     type: 'file',
    //     label: node.name,
    //     icon: 'img:/word_icon.svg',
    //     permissions: [
    //       {
    //         name: 'view_documenttreenode',
    //         label: "Voir",
    //         value: getDocumentTreePermissions(node.id).includes("view_documenttreenode"),
    //         displayedValue: getDocumentTreePermissions(node.id).includes("view_documenttreenode"),
    //         disabled: !projectPermissions.permissions.includes("documents_edit_deep_rights"),
    //         fromGroup: await isFromGroup(node.id, "view_documenttreenode"),
    //       }, {
    //         name: 'documents_view_deep_rights',
    //         label: "Modifier",
    //         value: getDocumentTreePermissions(node.id).includes("change_documenttreenode"),
    //         displayedValue: getDocumentTreePermissions(node.id).includes("view_documenttreenode"),
    //         disabled: !projectPermissions.permissions.includes("documents_edit_deep_rights"),
    //         fromGroup: false,
    //       }
    //     ],
    //   })
    // }
  }
  return result
}

function getTaskPermissions(tasksPermissions, taskId) {
  const permissions = tasksPermissions.permissions.filter((permission) => {
    return permission.objectId === taskId
  })
  if (permissions.length > 0) {
    return permissions[0].permissions
  }
  return []
}

async function parseProjectTasks(tasks) {
  const taskIds = tasks.map((task) => task.id)
  let tasksPermissions

  if (!view1UserOrGroupSelected.value) {
    tasksPermissions = {
      permissions: [],
    }
  } else {
    if (selectionIsGroup.value) {
      tasksPermissions = await userApi.getObjectsGroupPermissions(taskIds, "LegacyTask", view1UserOrGroupSelected.value)
    } else {
      tasksPermissions = await userApi.getObjectsPermissions(taskIds, "LegacyTask", view1UserOrGroupSelected.value)
    }
  }


  console.log("tasksPermissions", tasksPermissions)

  const result = []
  tasks.forEach((task) => {
    result.push({
      id: task.id,
      type: 'task',
      label: task.name,
      view: getTaskPermissions(tasksPermissions, task.id).includes("view_legacytask"),
      edit: getTaskPermissions(tasksPermissions, task.id).includes("change_legacytask"),
      children: [],
    })

  })
  return result
}

async function parseProjectSections(sections) {
  const result = []

  if (!sections) {
    return result
  }

  for (let i = 0; i < sections.length; i++) {
    const section = sections[i]
    const newChildren = await parseProjectSections(section.sections)
    const tasks = await parseProjectTasks(section.tasks)
    newChildren.push(...tasks)

    result.push({
      id: section.id,
      type: 'section',
      label: section.name,
      view: true,
      edit: true,
      children: newChildren,
    })
  }

  return result
}

async function updateSubProjects() {
  for (let i = 0; i < props.project.value.subProjects.length; i++) {
    const legacyProject = props.project.value.subProjects[i]

    let legacyProjectPermissions

    if (!view1UserOrGroupSelected.value) {
      legacyProjectPermissions = {
        permissions: [],
      }
    } else {
      if (selectionIsGroup.value) {
        legacyProjectPermissions = await userApi.getObjectGroupPermissions(legacyProject.id, "UserProjectLegacyProject", view1UserOrGroupSelected.value)
      } else {
        legacyProjectPermissions = await userApi.getObjectPermissions(legacyProject.id, "UserProjectLegacyProject", view1UserOrGroupSelected.value)
      }
    }

    console.log("legacyProjectPermissions", legacyProjectPermissions)

    const newProjectNode = {
      id: legacyProject.id,
      type: 'project',
      label: legacyProject.name,
      view: !legacyProjectPermissions.permissions.includes("legacy_project_view_deep_rights"),
      edit: !legacyProjectPermissions.permissions.includes("legacy_project_edit_deep_rights"),
      children: await parseProjectSections(legacyProject.sections)
    }
    permissionsNodes.value[2].children.push(newProjectNode)

  }
}


function toggleView() {
  view.value = view.value === "1" ? "2" : "1"
}

function findPermissionByName(permissions, name) {
  return permissions.find((permission) => {
    return permission.name === name
  })
}


function updateDocumentsViewRecursive(nodes, permissionName, newValue, newDisplayedValue, newDisabled) {

  nodes.forEach((node) => {
    const permission = findPermissionByName(node.permissions, permissionName)
    console.log("permission", permission)
    if (permission) {
      if (newValue !== undefined) {
        permission.value = newValue
      }
      if (newDisplayedValue !== undefined) {
        if (newDisplayedValue) {
          permission.displayedValue = true
        } else {
          permission.displayedValue = permission.value
        }
      }
      if (newDisabled !== undefined) {
        permission.disabled = newDisabled
      }
    }


    if (node.children) {
      updateDocumentsViewRecursive(node.children, permissionName, newValue, newDisplayedValue, newDisabled)
    }
  })
}

function updateDocumentsEditRecursive(nodes, permissionName, newValue, newDisplayedValue, newDisabled) {
  // nodes.forEach((node) => {
  //   node.editDisabled = newValue
  //   if (node.children) {
  //     updateDocumentsEditRecursive(node.children, newValue)
  //   }
  // })
  nodes.forEach((node) => {
    const permission = findPermissionByName(node.permissions, permissionName)
    console.log("permission", permission)
    if (permission) {
      if (newValue !== undefined) {
        permission.value = newValue
      }
      if (newDisplayedValue !== undefined) {
        if (newDisplayedValue) {
          permission.displayedValue = true
        } else {
          permission.displayedValue = permission.value
        }
      }
      if (newDisabled !== undefined) {
        permission.disabled = newDisabled
      }
    }


    if (node.children) {
      updateDocumentsEditRecursive(node.children, permissionName, newValue, newDisplayedValue, newDisabled)
    }
  })
}



function getDocumentTreePermissions(nodeId) {
  const permissions = documentsPermissions.permissions.filter((permission) => {
    return permission.objectId === nodeId
  })
  if (permissions.length > 0) {
    return permissions[0].permissions
  }
  return []
}

async function updateUserOrGroupPermissions() {
  if (!view1UserOrGroupSelected.value) {
    projectPermissions = {
      permissions: [],
    }
  } else {
    if (selectionIsGroup.value) {
      projectPermissions = await userApi.getObjectGroupPermissions(props.project.value.id, "UserProject", view1UserOrGroupSelected.value)
    } else {
      projectPermissions = await userApi.getObjectPermissions(props.project.value.id, "UserProject", view1UserOrGroupSelected.value)
    }
  }

  console.log("projectPermissions", projectPermissions)

  permissionsNodes.value = [
    {
      id: "project",
      type: "matter",
      label: "Dossier",
      edit_project_parameters: true,
      children: [

      ],
    },
    {
      id: "documents",
      type: "documents",
      label: "Documents",
      view: !projectPermissions.permissions.includes("documents_view_deep_rights"),
      edit: !projectPermissions.permissions.includes("documents_edit_deep_rights"),
      children: [

      ],
    },
    {
      id: "projets",
      type: "projects",
      label: "Opérations juridiques",
      children: [
      ],
    },
    {
      id: "notifications",
      type: "notifications",
      label: "Notifications",
      edit: true,
      receive: true,
      view: true,
      children: [

      ],
    },
    {
      id: "messages",
      type: "messages",
      label: "Messages",
      edit: true,
      view: true,
      children: [
      ],
    },
    {
      id: "users_and_groups",
      type: "users_and_groups",
      label: "Utilisateurs et groupes",
      edit: true,
      view: true,
      create: true,
      children: [
      ],
    },
  ]


  console.log("view1UserOrGroupSelected", view1UserOrGroupSelected.value)

  if (!view1UserOrGroupSelected.value) {
    documentsPermissions = {
      permissions: [],
    }
  } else {
    if (selectionIsGroup.value) {
      documentsPermissions = await userApi.getObjectsGroupPermissions(documentTreeNodesIds, "DocumentTreeNode", view1UserOrGroupSelected.value)
    } else {
      documentsPermissions = await userApi.getObjectsPermissions(documentTreeNodesIds, "DocumentTreeNode", view1UserOrGroupSelected.value)
    }
  }

  console.log("documentsPermissions", documentsPermissions)

  permissionsNodes.value[1].children = await parseDocumentTree(documentTree.children)

  await updateSubProjects()

  // if (view1UserOrGroupTreeRef.value) {
  //   view1UserOrGroupTreeRef.value.expandAll()
  // }
  if (view1PermissionsTreeRefProjects.value) {
    view1PermissionsTreeRefProjects.value.expandAll()
  }
}

function isGroup(nodeId) {
  console.log("isGroup", nodeId, groups.environmentGroups, groups.clientGroups)
  let result = groups.environmentGroups.find((group) => {
    return group.id === nodeId
  })
  if (result === undefined) {
    result = groups.clientGroups.find((group) => {
      return group.id === nodeId
    })
  }
  console.log("isGroup", result !== undefined)
  return result !== undefined
}

function updateDocumentsView(newValue) {
  console.log("updateDocumentsView", newValue, permissionsNodes.value)
  if (newValue) {
    updateDocumentsViewRecursive(permissionsNodes.value[1].children, "view_documenttreenode", undefined, true, true)
  } else {
    updateDocumentsViewRecursive(permissionsNodes.value[1].children, "view_documenttreenode", undefined, false, false)
  }

}

function updateDocumentsEdit(newValue) {
  console.log("updateDocumentsEdit", newValue)
  // updateDocumentsEditRecursive(permissionsNodes.value[1].children, newValue)
  if (newValue) {
    updateDocumentsEditRecursive(permissionsNodes.value[1].children, "change_documenttreenode", undefined, true, true)
  } else {
    updateDocumentsEditRecursive(permissionsNodes.value[1].children, "change_documenttreenode", undefined, false, false)
  }
}


function saveDocumentsViewRecursive(nodes) {
  nodes.forEach((node) => {
    console.log('saving node', node)
    if (node.permissions[0].value) {
      console.log("enable view document tree node")
      if (selectionIsGroup.value) {
        userApi.updateObjectGroupsPermissions(
          node.id,
          "DocumentTreeNode",
          [view1UserOrGroupSelected.value],
          ["view_documenttreenode"],
          [],
        )
      } else {
        userApi.updateObjectPermissions(
          node.id,
          "DocumentTreeNode",
          [view1UserOrGroupSelected.value],
          ["view_documenttreenode"],
          [],
        )
      }


    } else {
      console.log("disable view document tree node")

      if (selectionIsGroup.value) {
        userApi.updateObjectGroupsPermissions(
          node.id,
          "DocumentTreeNode",
          [view1UserOrGroupSelected.value],
          [],
          ["view_documenttreenode"],
        )
      } else {
        userApi.updateObjectPermissions(
          node.id,
          "DocumentTreeNode",
          [view1UserOrGroupSelected.value],
          [],
          ["view_documenttreenode"],
        )
      }


    }

    if (node.children) {
      saveDocumentsViewRecursive(node.children)
    }
  })
}

function saveDocumentsEditRecursive(nodes) {
  nodes.forEach((node) => {
    if (node.edit) {
      console.log("enable edit document tree node")

      if (selectionIsGroup.value) {
        userApi.updateObjectGroupsPermissions(
          node.id,
          "DocumentTreeNode",
          [view1UserOrGroupSelected.value],
          ["change_documenttreenode"],
          [],
        )
      } else {
        userApi.updateObjectPermissions(
          node.id,
          "DocumentTreeNode",
          [view1UserOrGroupSelected.value],
          ["change_documenttreenode"],
          [],
        )
      }


    } else {
      console.log("disable edit document tree node")

      if (selectionIsGroup.value) {
        userApi.updateObjectGroupsPermissions(
          node.id,
          "DocumentTreeNode",
          [view1UserOrGroupSelected.value],
          [],
          ["change_documenttreenode"],
        )
      } else {
        userApi.updateObjectPermissions(
          node.id,
          "DocumentTreeNode",
          [view1UserOrGroupSelected.value],
          [],
          ["change_documenttreenode"],
        )
      }


    }

    if (node.children) {
      saveDocumentsEditRecursive(node.children)
    }
  })
}

function saveSectionsViewRecursive(nodes) {
  nodes.forEach((node) => {
    if (node.type == 'section') {


    } else {
      // task NODE
      if (node.view) {
        console.log("enable view task node")

        if (selectionIsGroup.value) {
          userApi.updateObjectGroupsPermissions(
            node.id,
            "LegacyTask",
            [view1UserOrGroupSelected.value],
            ["view_legacytask"],
            [],
          )
        } else {
          userApi.updateObjectPermissions(
            node.id,
            "LegacyTask",
            [view1UserOrGroupSelected.value],
            ["view_legacytask"],
            [],
          )
        }


      } else {
        console.log("disable view task node")

        if (selectionIsGroup.value) {
          userApi.updateObjectGroupsPermissions(
            node.id,
            "LegacyTask",
            [view1UserOrGroupSelected.value],
            [],
            ["view_legacytask"],
          )
        } else {
          userApi.updateObjectPermissions(
            node.id,
            "LegacyTask",
            [view1UserOrGroupSelected.value],
            [],
            ["view_legacytask"],
          )
        }

      }
    }

    if (node.children) {
      saveSectionsViewRecursive(node.children)
    }
  })
}

function save() {
  const userId = view1UserOrGroupSelected.value

  if (permissionsNodes.value[1].view) {
    console.log("view all docs")

    if (selectionIsGroup.value) {
      userApi.updateObjectGroupsPermissions(
        props.project.value.id,
        "UserProject",
        [userId],
        [],
        ["documents_view_deep_rights"]
      )
    } else {
      userApi.updateObjectPermissions(
        props.project.value.id,
        "UserProject",
        [userId],
        [],
        ["documents_view_deep_rights"]
      )
    }


  } else {
    console.log("deep rights on view docs")
    if (selectionIsGroup.value) {
      userApi.updateObjectGroupsPermissions(
        props.project.value.id,
        "UserProject",
        [userId],
        ["documents_view_deep_rights"],
        []
      )
    } else {
      userApi.updateObjectPermissions(
        props.project.value.id,
        "UserProject",
        [userId],
        ["documents_view_deep_rights"],
        []
      )
    }
  }
  saveDocumentsViewRecursive(permissionsNodes.value[1].children)

  if (permissionsNodes.value[1].edit) {
    console.log("edit all docs")
    if (selectionIsGroup.value) {
      userApi.updateObjectGroupsPermissions(
        props.project.value.id,
        "UserProject",
        [userId],
        [],
        ["documents_edit_deep_rights"]
      )
    } else {
      userApi.updateObjectPermissions(
        props.project.value.id,
        "UserProject",
        [userId],
        [],
        ["documents_edit_deep_rights"]
      )
    }


  } else {
    console.log("deep rights on edit docs")

    if (selectionIsGroup.value) {
      userApi.updateObjectGroupsPermissions(
        props.project.value.id,
        "UserProject",
        [userId],
        ["documents_edit_deep_rights"],
        []
      )
    } else {
      userApi.updateObjectPermissions(
        props.project.value.id,
        "UserProject",
        [userId],
        ["documents_edit_deep_rights"],
        []
      )
    }

  }
  saveDocumentsEditRecursive(permissionsNodes.value[1].children)

  permissionsNodes.value[2].children.forEach((project) => {
    console.log("saving project", project)
    if (project.view) {
      console.log("view project")

      if (selectionIsGroup.value) {
        userApi.updateObjectGroupsPermissions(
          project.id,
          "UserProjectLegacyProject",
          [userId],
          [],
          ["legacy_project_view_deep_rights"]
        )
      } else {
        userApi.updateObjectPermissions(
          project.id,
          "UserProjectLegacyProject",
          [userId],
          [],
          ["legacy_project_view_deep_rights"]
        )
      }


    } else {
      console.log("deep rights on view project")

      if (selectionIsGroup.value) {
        userApi.updateObjectGroupsPermissions(
          project.id,
          "UserProjectLegacyProject",
          [userId],
          ["legacy_project_view_deep_rights"],
          []
        )
      } else {
        userApi.updateObjectPermissions(
          project.id,
          "UserProjectLegacyProject",
          [userId],
          ["legacy_project_view_deep_rights"],
          []
        )
      }

    }

    saveSectionsViewRecursive(project.children)

    if (project.edit) {
      console.log("edit project")
      if (selectionIsGroup.value) {
        userApi.updateObjectGroupsPermissions(
          project.id,
          "UserProjectLegacyProject",
          [userId],
          [],
          ["legacy_project_edit_deep_rights"]
        )
      } else {
        userApi.updateObjectPermissions(
          project.id,
          "UserProjectLegacyProject",
          [userId],
          [],
          ["legacy_project_edit_deep_rights"]
        )
      }

    } else {
      console.log("deep rights on edit project")
      if (selectionIsGroup.value) {
        userApi.updateObjectGroupsPermissions(
          project.id,
          "UserProjectLegacyProject",
          [userId],
          ["legacy_project_edit_deep_rights"],
          []
        )
      } else {
        userApi.updateObjectPermissions(
          project.id,
          "UserProjectLegacyProject",
          [userId],
          ["legacy_project_edit_deep_rights"],
          []
        )
      }

    }
  })
}

function usersUpdated() {
  console.log("usersUpdated")
  updateUsersAndGroups()
}

function groupsUpdated() {
  console.log("groupsUpdated")
  updateUsersAndGroups()
}


watch(view1UserOrGroupSelected, async () => {
  console.log("view1UserOrGroupSelected", view1UserOrGroupSelected.value)
  isLoading.value = true
  Loading.show()
  await updateUserOrGroupPermissions()
  updateDocumentsView(permissionsNodes.value[1].view)
  isLoading.value = false
  Loading.hide()
})

watch(view1PermissionsTreeRefMatter, () => {
  console.log("view1PermissionsTreeRefMatter", view1PermissionsTreeRefMatter.value)
  if (view1PermissionsTreeRefMatter.value) {
    view1PermissionsTreeRefMatter.value.expandAll()
  }
})

watch(view1PermissionsTreeRefDocuments, () => {
  console.log("view1PermissionsTreeRefDocuments", view1PermissionsTreeRefDocuments.value)
  if (view1PermissionsTreeRefDocuments.value) {
    view1PermissionsTreeRefDocuments.value.expandAll()
  }
})

watch(view1PermissionsTreeRefProjects, () => {
  console.log("view1PermissionsTreeRefProjects", view1PermissionsTreeRefProjects.value)
  if (view1PermissionsTreeRefProjects.value) {
    view1PermissionsTreeRefProjects.value.expandAll()
  }
})

watch(view1PermissionsTreeRefNotifications, () => {
  console.log("view1PermissionsTreeRefNotifications", view1PermissionsTreeRefNotifications.value)
  if (view1PermissionsTreeRefNotifications.value) {
    view1PermissionsTreeRefNotifications.value.expandAll()
  }
})

onMounted(() => {
  nextTick(() => {
    if (view1PermissionsTreeRefMatter.value) {
      view1PermissionsTreeRefMatter.value.expandAll()
    }
    if (view1PermissionsTreeRefDocuments.value) {
      view1PermissionsTreeRefDocuments.value.expandAll()
    }
    if (view1PermissionsTreeRefProjects.value) {
      view1PermissionsTreeRefProjects.value.expandAll()
    }
    if (view1PermissionsTreeRefNotifications.value) {
      view1PermissionsTreeRefNotifications.value.expandAll()
    }
  })
  selectionIsGroup.value = isGroup(view1UserOrGroupSelected.value)
  // view1UserOrGroupTreeRef.value.expandAll()
  // treeRef.value.expandAll()
  // treeRef2.value.expandAll()
  // treeRef4.value.expandAll()
})


await updateUsersAndGroups()
</script>

<template>
  <q-splitter v-model="splitterModel" style="height: calc(100vh - 226px);">


    <!--  View 1 left : Users and groups / View 2 left: Documents and projects -->
    <template v-slot:before>

      <!-- View 1 left: Users and groups -->
      <div v-if="view === '1'" class="q-pa-md">
        <a href="#" class="q-mb-md" @click="toggleView">Utilisateurs et groupes</a>
        <q-separator style="margin-top: 8px;"></q-separator>
        <div>
          <q-tree ref="view1UserOrGroupTreeRef" node-key="id" :nodes="usersNodes"
            style="margin-left: 8px; padding-bottom: 16px; margin-right: 2px;" dense v-model:expanded="expandedNodes3"
            v-model:selected="view1UserOrGroupSelected" selected-color="primary" class="document-tree"
            :default-expand-all="true" no-selection-unset>
            <template v-slot:default-header="prop">
              <div style="padding: 0px; overflow: hidden"
                class="row no-wrap justify-start items-start content-start full-width">
                <div v-if="prop.node.type === 0">&nbsp;</div>
                <UsersNode v-if="prop.node.type === 'users'" :project="project" :element="prop.node"
                  @users-updated="usersUpdated">
                </UsersNode>
                <GroupsNode v-if="prop.node.type === 'groups'" :project="project" :element="prop.node"
                  @groups-updated="groupsUpdated">
                </GroupsNode>
                <UserNode v-if="prop.node.type === 'user'" :project="project" :element="prop.node"
                  :show-permissions="false" :treeRef="view1UserOrGroupTreeRef" @users-updated="usersUpdated">
                </UserNode>
                <GroupNode v-if="prop.node.type === 'group'" :project="project" :element="prop.node"
                  :show-permissions="false" :treeRef="view1UserOrGroupTreeRef"></GroupNode>
              </div>
            </template>
          </q-tree>
        </div>
      </div>


      <!-- View 1 left: Documents and projects -->
      <div v-if="view === '2'" class="q-pa-md">
        <a href="#" class="q-mb-md" @click="toggleView">Documents et opérations juridiques</a>
        <q-separator style="margin-top: 8px;"></q-separator>
        <div>
          <q-tree node-key="id" :nodes="permissionsNodes"
            style="margin-left: 8px; padding-bottom: 16px, margin-right: 2px;" dense v-model:expanded="expandedNodes2"
            v-model:selected="selectedNode2" selected-color="primary" class="document-tree" :default-expand-all="true">
            <template v-slot:default-header="prop">
              <div style="padding: 0px; overflow: hidden"
                class="row no-wrap justify-start items-start content-start full-width">
                <div v-if="prop.node.type === 0">&nbsp;</div>
                <DocumentsNode v-if="prop.node.type === 'documents'" :element="prop.node" :treeRef="treeRef2">
                </DocumentsNode>
                <ProjectsNode v-if="prop.node.type === 'projects'" :element="prop.node" :treeRef="treeRef2">
                </ProjectsNode>
                <DirectoryNode v-if="prop.node.type === 'directory'" :element="prop.node" :treeRef="treeRef2">
                </DirectoryNode>
                <FileNode v-if="prop.node.type === 'file'" :element="prop.node" :treeRef="treeRef2"></FileNode>
                <ProjectNode v-if="prop.node.type === 'project'" :element="prop.node" :treeRef="treeRef2">
                </ProjectNode>
                <SectionNode v-if="prop.node.type === 'section'" :element="prop.node" :treeRef="treeRef2">
                </SectionNode>
                <TaskNode v-if="prop.node.type === 'task'" :element="prop.node" :treeRef="treeRef2">
                </TaskNode>
              </div>
            </template>
          </q-tree>
        </div>
      </div>
    </template>


    <!--  View 1 right: Documents and projects  and groups / View 2 right: Users -->
    <template v-slot:after>

      <!-- View 1 right: Documents and projects -->
      <div v-if="view === '1'" class="q-pa-md">
        <div class="q-mb-md"></div>
        <div>

          <q-tabs v-model="view1Tab" dense class="text-grey" active-color="primary" indicator-color="primary"
            align="justify" narrow-indicator>
            <q-tab name="matter" label="Dossier" />
            <q-tab name="documents" label="Documents" />
            <q-tab name="projects" label="Opérations juridiques" />
            <q-tab name="messages" label="Messages" />
            <q-tab name="notifications" label="Notifications" />
            <q-tab name="users_and_groups" label="Utilisateurs et groupes" />
          </q-tabs>

          <q-separator />

          <q-tab-panels v-model="view1Tab" animated>
            <q-tab-panel name="matter">
              <q-tree ref="view1PermissionsTreeRefMatter" node-key="id" :nodes="[permissionsNodes[0]]"
                style="margin-left: 8px; padding-bottom: 16px, margin-right: 2px;" dense
                v-model:expanded="expandedNodes" v-model:selected="selectedNode" selected-color="primary"
                class="document-tree" :default-expand-all="true">
                <template v-slot:default-header="prop">
                  <div style="padding: 0px; overflow: hidden"
                    class="row no-wrap justify-start items-start content-start full-width">
                    <div v-if="prop.node.type === 0">&nbsp;</div>
                    <MatterNode v-if="prop.node.type === 'matter'" :element="prop.node"
                      :treeRef="view1PermissionsTreeRefMatter" :showPermissions="true"
                      @update:view="updateDocumentsView" @update:edit="updateDocumentsEdit">
                    </MatterNode>
                  </div>
                </template>
              </q-tree>
            </q-tab-panel>

            <q-tab-panel name="documents">
              <q-tree ref="view1PermissionsTreeRefDocuments" node-key="id" :nodes="[permissionsNodes[1]]"
                style="margin-left: 8px; padding-bottom: 16px, margin-right: 2px;" dense
                v-model:expanded="expandedNodes" v-model:selected="selectedNode" selected-color="primary"
                class="document-tree" :default-expand-all="true">
                <template v-slot:default-header="prop">
                  <div style="padding: 0px; overflow: hidden"
                    class="row no-wrap justify-start items-start content-start full-width">
                    <div v-if="prop.node.type === 0">&nbsp;</div>
                    <MatterNode v-if="prop.node.type === 'matter'" :element="prop.node"
                      :treeRef="view1PermissionsTreeRefDocuments" :showPermissions="true"
                      @update:view="updateDocumentsView" @update:edit="updateDocumentsEdit">
                    </MatterNode>
                    <DocumentsNode v-if="prop.node.type === 'documents'" :element="prop.node"
                      :treeRef="view1PermissionsTreeRefDocuments" :showPermissions="true"
                      @update:view="updateDocumentsView" @update:edit="updateDocumentsEdit">
                    </DocumentsNode>
                    <DirectoryNode v-if="prop.node.type === 'directory'" :element="prop.node"
                      :treeRef="view1PermissionsTreeRefDocuments" :showPermissions="true">
                    </DirectoryNode>
                    <FileNode v-if="prop.node.type === 'file'" :element="prop.node"
                      :treeRef="view1PermissionsTreeRefDocuments" :showPermissions="true"></FileNode>
                  </div>
                </template>
              </q-tree>
            </q-tab-panel>

            <q-tab-panel name="projects">
              <q-tree ref="view1PermissionsTreeRefProjects" node-key="id" :nodes="[permissionsNodes[2]]"
                style="margin-left: 8px; padding-bottom: 16px, margin-right: 2px;" dense
                v-model:expanded="expandedNodes" v-model:selected="selectedNode" selected-color="primary"
                class="document-tree" :default-expand-all="true">
                <template v-slot:default-header="prop">
                  <div style="padding: 0px; overflow: hidden"
                    class="row no-wrap justify-start items-start content-start full-width">
                    <div v-if="prop.node.type === 0">&nbsp;</div>
                    <ProjectsNode v-if="prop.node.type === 'projects'"></ProjectsNode>
                    <ProjectNode v-if="prop.node.type === 'project'" :element="prop.node"
                      :treeRef="view1PermissionsTreeRefProjects" :showPermissions="true">
                    </ProjectNode>
                    <SectionNode v-if="prop.node.type === 'section'" :element="prop.node"
                      :treeRef="view1PermissionsTreeRefProjects" :showPermissions="true">
                    </SectionNode>
                    <TaskNode v-if="prop.node.type === 'task'" :element="prop.node"
                      :treeRef="view1PermissionsTreeRefProjects" :showPermissions="true">
                    </TaskNode>
                  </div>
                </template>
              </q-tree>
            </q-tab-panel>

            <q-tab-panel name="notifications">
              <q-tree ref="view1PermissionsTreeRefNotifications" node-key="id" :nodes="[permissionsNodes[3]]"
                style="margin-left: 8px; padding-bottom: 16px, margin-right: 2px;" dense
                v-model:expanded="expandedNodes" v-model:selected="selectedNode" selected-color="primary"
                class="document-tree" :default-expand-all="true">
                <template v-slot:default-header="prop">
                  <div style="padding: 0px; overflow: hidden"
                    class="row no-wrap justify-start items-start content-start full-width">
                    <div v-if="prop.node.type === 0">&nbsp;</div>
                    <NotificationsNode v-if="prop.node.type === 'notifications'" :element="prop.node"
                      :treeRef="view1PermissionsTreeRefProjects" :showPermissions="true">
                    </NotificationsNode>
                  </div>
                </template>
              </q-tree>
            </q-tab-panel>

            <q-tab-panel name="messages">
              <q-tree ref="view1PermissionsTreeRefNotifications" node-key="id" :nodes="[permissionsNodes[4]]"
                style="margin-left: 8px; padding-bottom: 16px, margin-right: 2px;" dense
                v-model:expanded="expandedNodes" v-model:selected="selectedNode" selected-color="primary"
                class="document-tree" :default-expand-all="true">
                <template v-slot:default-header="prop">
                  <div style="padding: 0px; overflow: hidden"
                    class="row no-wrap justify-start items-start content-start full-width">
                    <div v-if="prop.node.type === 0">&nbsp;</div>
                    <MessagesNode v-if="prop.node.type === 'messages'" :element="prop.node"
                      :treeRef="view1PermissionsTreeRefProjects" :showPermissions="true">
                    </MessagesNode>
                  </div>
                </template>
              </q-tree>
            </q-tab-panel>

            <q-tab-panel name="users_and_groups">
              <q-tree ref="view1PermissionsTreeRefNotifications" node-key="id" :nodes="[permissionsNodes[5]]"
                style="margin-left: 8px; padding-bottom: 16px, margin-right: 2px;" dense
                v-model:expanded="expandedNodes" v-model:selected="selectedNode" selected-color="primary"
                class="document-tree" :default-expand-all="true">
                <template v-slot:default-header="prop">
                  <div style="padding: 0px; overflow: hidden"
                    class="row no-wrap justify-start items-start content-start full-width">
                    <div v-if="prop.node.type === 0">&nbsp;</div>
                    <UsersAndGroupsNode v-if="prop.node.type === 'users_and_groups'" :element="prop.node"
                      :treeRef="view1PermissionsTreeRefProjects" :showPermissions="true">
                    </UsersAndGroupsNode>
                  </div>
                </template>
              </q-tree>
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </div>

      <!-- View 2 right: Users -->
      <div v-if="view === '2'" class="q-pa-md">
        <div class="q-mb-md"></div>
        <div>
          <q-tree node-key="id" :nodes="usersNodes" style="margin-left: 8px; padding-bottom: 16px, margin-right: 2px;"
            dense v-model:expanded="expandedNodes4" v-model:selected="selectedNode4" selected-color="primary"
            class="document-tree" :default-expand-all="true">
            <template v-slot:default-header="prop">
              <div style="padding: 0px; overflow: hidden"
                class="row no-wrap justify-start items-start content-start full-width">
                <div v-if="prop.node.type === 0">&nbsp;</div>
                <UsersNode v-if="prop.node.type === 'users'" :element="prop.node" :treeRef="treeRef4"
                  :showPermissions="true">
                </UsersNode>
                <GroupsNode v-if="prop.node.type === 'groups'" :element="prop.node" :treeRef="treeRef4"
                  :showPermissions="true">
                </GroupsNode>
                <UserNode v-if="prop.node.type === 'user'" :element="prop.node" :treeRef="treeRef4"
                  :showPermissions="true">
                </UserNode>
                <GroupNode v-if="prop.node.type === 'group'" :element="prop.node" :treeRef="treeRef4"
                  :showPermissions="true"></GroupNode>
              </div>
            </template>
          </q-tree>
        </div>
      </div>

    </template>

  </q-splitter>

  <div class="row" style="margin: 16px;">
    <div class="col">
    </div>
    <div class="col-auto">
      <q-btn label="Enregistrer" color="primary" @click="save"></q-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// node-body
// :deep(.q-tree__node-body) {
//   padding: 0px;
// }

// :deep(.q-tree__node--parent > .q-tree__node-collapsible > .q-tree__node-body) {
//   padding-bottom: 0px;
// }

// .document-tree {
//   margin-right: 8px;
// }</style>
